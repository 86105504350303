<template>
	<v-card
		elevation="2"
		style="max-width: 20em;"
		class="mx-auto ma-auto pa-3"
	>
		<v-card-title>Data Download</v-card-title>
		<v-form @submit.prevent>
			<v-text-field
				type="email"
				v-model="email"
				:rules="emailRules"
				label="Email"
				required
			>
			</v-text-field>
			<v-text-field
				v-model="code"
				label="Code"
				placeholder="123456"
				clearable
			></v-text-field>

		</v-form>
		<v-card-actions>
			<v-btn
				block
				:loading="loading"
				:disabled="loading"
				color="success"
				@click="recaptcha"
			>Request Download
				<template v-slot:loader>
					<span class="custom-loader">
						<v-icon light>mdi-cached</v-icon>
					</span>
				</template>
			</v-btn>
		</v-card-actions>
		<v-sheet v-if="error.type">
			<v-alert :type="error.type">{{error.message}}</v-alert>
		</v-sheet>
	</v-card>
</template>
<script>
let fnGetFileNameFromContentDispostionHeader = function (header) {
	let contentDispostion = header.split(";");
	//const fileNameToken = `filename*=UTF-8''`;
	console.log("contentDisposition", contentDispostion);
	let fileName = "downloaded.pdf";
	for (let thisValue of contentDispostion) {
		console.log("this value", thisValue);
		if (thisValue.indexOf("filename=") > -1) {
			fileName = thisValue.split("filename=")[1].trim().replaceAll("'", "");
			break;
		}
	}
	console.log("returning filename", fileName);
	return fileName;
};
//https://pqina.nl/blog/rename-a-file-with-javascript/
const createFile = (bits, name, options) => {
	try {
		// If this fails, we go for Blob
		return new File(bits, name, options);
	} catch (e) {
		// If we're here a new File could not be constructed
		var myBlob = new Blob(bits, options || {});
		myBlob.lastModified = new Date();
		myBlob.name = name;
		return myBlob;
	}
};
const downloadFile = (data, fileName) => {
	var a = document.createElement("a");
	document.body.appendChild(a);
	a.style = "display: none";
	let contentType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
	if (fileName.endsWith(".csv")) {
		contentType = "text/csv";
	}
	let blob = new Blob([data], {
		type: contentType,
	});
	let url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
};
export default {
	data() {
		return {
			loading: false,
			code: this.$route.query.code || "",
			email: this.$route.query.email || "",
			error: {
				type: false,
				message: "",
			},
			emailRules: [
				//(v) => !!v || "E-mail is required",
				(v) =>
					/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
						v
					) || "E-mail must be valid",
			],
		};
	},
	methods: {
		async recaptcha() {
			if (!this.email || !this.code) {
				this.error.type = "error";
				this.error.message = "Both email and code are required";
				return;
			}
			this.loading = true;
			// (optional) Wait until recaptcha has been loaded.
			await this.$recaptchaLoaded();

			// Execute reCAPTCHA with action "login".
			const token = await this.$recaptcha("login");
			console.log("token", token);
			const request = new Request(
				"https://us-central1-mentalcpr.cloudfunctions.net/download",
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						token: token,
						code: this.code,
						email: this.email,
					}),
				}
			);
			await fetch(request)
				.then(async (res) => {
					console.log("res", res);
					if (res.status === 200) {
						this.error.type = false;
						this.error.message = "";
						return {
							filename: fnGetFileNameFromContentDispostionHeader(
								res.headers.get("content-disposition")
							),
							blob: await res.blob(),
						};
					} else {
						if (res.status === 400) {
							this.error.type = "error";
							this.error.message =
								"Please double check that you provided all necessary input";
						} else if (res.status === 403) {
							this.error.type = "error";
							this.error.type =
								"You don't have permission to access this file or your token expired, please try again";
						} else if (res.status === 404) {
							this.error.type = "warning";
							this.error.message =
								"Error looking up your file, either there isn't a file loaded for you, your code expired, or your code was entered incorrectly";
						} else if (res.status === 500) {
							this.error.type = "error";
							this.error.message =
								"There was an error processing your request, if this continues to happen please reachout to matt@ethereallab.app";
						}

						return false;
					}
				})
				.then((resObj) => {
					if (resObj) {
						console.log("file name", resObj.filename);
						let contentType =
							"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
						if (resObj.filename.endsWith(".csv")) {
							contentType = "text/csv";
						}
						let file = createFile([resObj.blob], resObj.filename, {
							type: contentType,
							//"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
						});
						downloadFile(file, resObj.filename);
						this.error.type = "success";
						this.error.message =
							"Download successful, please check your browser's downloads folder.";
						return true;
					} else {
						return false;
					}
				});
			this.loading = false;
		},
	},
};
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>